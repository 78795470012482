import { Model } from 'vue-powernap'

export default class Company extends Model {
  get name () {
    return 'companies'
  }

  url (params = {}) {
    return `/api/companies`
  }

  get fields () {
    return {
      id: this.string(),
      name: this.string(),
      position: this.integer(),
      logoActive: this.string(),
      logoActiveId: this.uuid(),
      logoInactive: this.string(),
      logoInactiveId: this.uuid(),
      visible: this.boolean()
    }
  }

  get relationships () {
    return {
      categories: this.hasMany('companies/categories')
    }
  }
}
