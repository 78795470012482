import { Model } from 'vue-powernap'

export default class ProductFile extends Model {
  get name () {
    return 'product-files'
  }

  url (params = {}) {
    return '/api/product-files'
  }

  get fields () {
    return {
      id: this.string(),
      name: this.string(),
      fileId: this.uuid(),
      file: this.string(),
      fileType: this.string(),
      products: this.attr([])
    }
  }

  get relationships () {
    return {
      files: this.hasMany('companies/categories/products/files')
    }
  }
}
