
import Vue from 'vue'

import Loading from '../Loading.vue'
import ProductItemFiles from '../ProductItemFiles.vue'
import {Route} from 'vue-router'
import TipTap from '@/components/TipTap.vue'
import ProductFixedFormat from '@/components/ProductFixedFormat.vue'
import TextAdTable from '@/components/TextAdTable.vue'
import NumberInput from '@/components/NumberInput.vue'

export default Vue.extend({
  name: 'AdminProduct',
  components: {
    NumberInput,
    TextAdTable,
    ProductFixedFormat,
    TipTap,
    Loading,
    ProductItemFiles
  },
  props: {
    companyId: {
      type: String,
      required: true
    },
    categoryId: {
      type: String,
      required: true
    },
    productId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      tab: 0,
      subTab: null as number | null,
      newProductFiles: [] as Array<any>,
      fixedFormats: [] as Array<any>,
      product: {} as any,
      loading: true,
      file: null as object | null
    }
  },
  computed: {
    stateProduct(): object {
      if (this.productId !== 'new') {
        return this.$store.get('companies/categories/products', this.productId, {
          companyId: this.companyId,
          categoryId: this.categoryId
        })
      } else {
        return this.$store.new('companies/categories/products')
      }
    },
    displayFormatError(): boolean {
      return this.$validator.errors.has('printMethod') || this.$validator.errors.has('printSpace') ||
        this.$validator.errors.has('adColumnWidth') || this.$validator.errors.has('adColumnSpace') ||
        this.$validator.errors.has('adColumnNumber') || this.$validator.errors.has('textColumnWidth') ||
        this.$validator.errors.has('textColumnSpace') || this.$validator.errors.has('textColumnNumber') ||
        this.$validator.errors.has('fixedFormat')
    },
    counterpartError(): boolean {
      return this.$validator.errors.has('counterpartPhone') || this.$validator.errors.has('counterpartMail') ||
        this.$validator.errors.has('counterpartFax') || this.$validator.errors.has('counterpartNotes') ||
        this.$validator.errors.has('deliveryTime')
    },
    counterpartBookingError(): boolean {
      return this.$validator.errors.has('counterpartBookingName') || this.$validator.errors.has('counterpartBookingPhone') ||
        this.$validator.errors.has('counterpartBookingMail')
    },
    activeTab: {
      get(): number | null {
        return this.subTab
      },
      set(newValue: number | null) {
        this.subTab = newValue
      }
    }
  },
  async mounted(): Promise<void> {
    this.loading = true
    await this.$store.find('companies', this.companyId)
    await this.$store.findAll('product-files')
    await this.$store.findAll('companies/categories', {
      companyId: this.companyId
    })
    await this.$store.findAll('companies/categories/products', {
      companyId: this.companyId,
      categoryId: this.categoryId
    })
    if (this.productId !== 'new') {
      await this.$store.findAll('companies/categories/products/files', {
        companyId: this.companyId,
        categoryId: this.categoryId,
        productId: this.productId
      })

      await this.$store.findAll('companies/categories/products/fixed-formats', {
        companyId: this.companyId,
        categoryId: this.categoryId,
        productId: this.productId
      })
    }
    this.product = Object.assign({}, this.stateProduct)

    if (this.product.showText) {
      this.activeTab = 1
    } else {
      this.activeTab = 0
    }

    this.loading = false
  },
  methods: {
    pushTo(): void {
      if (process.env.NODE_ENV === 'production') {
        location.replace(`${process.env.VUE_APP_DOMAIN_NAME}/#/${this.companyId}#${this.categoryId}.${this.productId}`)
      } else {
        this.$router.push(`/${this.companyId}#${this.categoryId}.${this.productId}`)
      }
    },
    async submit(): Promise<void> {
      const valid = await this.$validator.validateAll()
      if (valid) {
        this.dialog = false
        this.loading = true
        if (this.product.id) {
          try {
            const container = document.createElement('div')
            container.innerHTML = this.product.text
            const img = container.querySelector('img')
            if (this.file && !this.product.fileSlug) {
              const response = await this.$store.call('upload-file', {
                data: this.file
              })
              if (img) {
                img.src = response.file
                this.product.text = container.innerHTML
              }
              this.product.fileSlug = response.id
            } else if (!this.file && !img && this.product.fileSlug) {
              await this.$store.call('delete-file', {
                id: this.product.fileSlug
              })
              this.product.fileSlug = null
            } else if (this.file && img && this.product.fileSlug) {
              const response = await this.$store.call('update-file', {
                data: this.file,
                id: this.product.fileSlug
              })
              if (img) {
                img.src = response.file
                this.product.text = container.innerHTML
              }
              this.product.fileSlug = response.id
            }

            await this.$store.update('companies/categories/products', this.product, {
              companyId: this.companyId,
              categoryId: this.categoryId
            })
            await this.saveFixFormats(this.productId)

            try {
              await this.createProductFilesWithRefs(this.product)
              this.product = Object.assign({}, this.stateProduct)
              this.$eventBus.setSuccess('Sie haben erfolgreich das Produkt aktualisiert!')
              this.newProductFiles = []
            } catch (e) {
              this.$eventBus.setError(
                'Es ist ein Fehler beim Speichern der Dateien aufgetreten aufgetreten! Bitte Überprüfen Sie Ihre Zuweisungen, ' +
                'eventuell auf doppelte.'
              )
            }
          } catch (e: any) {
            if (e.response.status === 409) {
              this.$eventBus.setError('Das Produkt existiert bereits, bitte wählen Sie einen anderen Namen!')
            } else {
              this.$eventBus.setError('Es ist ein Fehler aufgetreten! Bitte Überprüfen Sie Ihre Daten.')
            }
          }
        } else {
          try {
            const product = (await this.$store.create('companies/categories/products', this.product, {
              companyId: this.companyId,
              categoryId: this.categoryId
            })).json
            await this.saveFixFormats(product.id)

            try {
              await this.createProductFilesWithRefs(product)
              this.$eventBus.setSuccess('Sie haben erfolgreich das Produkt angelegt!')
              await this.$router.push({
                name: 'AdminProduct',
                params: {
                  companyId: this.companyId,
                  categoryId: this.categoryId,
                  productId: product.id
                }
              })
            } catch (e) {
              this.$eventBus.setError('Es ist ein Fehler beim Speichern der Dateien aufgetreten' +
                ' aufgetreten! Bitte Überprüfen Sie Ihre Zuweisungen, ' + 'eventuell auf doppelte. Das Produkt ' +
                'wurde aber dennoch angelegt.')
              await this.$router.push({
                name: 'AdminProduct',
                params: {
                  companyId: this.companyId,
                  categoryId: this.categoryId,
                  productId: product.id
                }
              })
            }
          } catch (e: any) {
            if (e.response.status === 409) {
              this.$eventBus.setError('Das Produkt existiert bereits, bitte wählen Sie einen anderen Namen!')
            } else {
              this.$eventBus.setError('Es ist ein Fehler aufgetreten! Bitte Überprüfen Sie Ihre Daten.')
            }
          }
        }
      } else {
        this.$eventBus.setError('Sie haben nicht alle erforderlichen Felder ausgefüllt. Bitte überprüfen Sie ihre Daten.')
      }
      this.$emit('rerender')
      this.loading = false
    },
    async deleteProduct(): Promise<void> {
      this.$eventBus.closeInfoDialog()
      try {
        await this.$store.delete('companies/categories/products', this.product.id, {
          companyId: this.companyId,
          categoryId: this.categoryId
        })
        await this.$router.replace(`/admin/${this.companyId}/${this.categoryId}`)
        this.$eventBus.setSuccess('Sie haben erfolgreich das Produkt gelöscht.')
      } catch (e) {
        this.$eventBus.setError('Beim Löschen ist ein Fehler aufgetreten, bitte kontaktieren Sie einen Administrator')
      }
    },
    openDeleteDialog(): void {
      this.$eventBus.openInfoDialog({
        error: true,
        whiteColor: true,
        action: this.deleteProduct,
        text: 'Möchten Sie wirklich das Produkt löschen?'
      })
    },
    cancel(): void {
      this.product = Object.assign({}, this.stateProduct)
      this.$router.replace(`/admin/${this.companyId}/${this.categoryId}`)
    },
    async createProductFilesWithRefs(product: Model<any>, isNew = false): Promise<void | Route> {
      const newProductFiles = []
      for (let item of this.newProductFiles) {
        if (!item.productFile.id) {
          const possibilities = newProductFiles.filter(
            file => file.name === item.productFile.file.name && file.fileType === item.productFile.fileType
          )
          if (possibilities.length === 0) {
            try {
              let formData = new FormData()
              formData.append('file', item.productFile.file)

              let picture = await this.$store.call('upload-file', {data: formData})
              item.productFile.fileId = picture.id

              const newProductFile = await this.$store.create('product-files', item.productFile)
              newProductFiles.push(newProductFile.json)
              item.productFileId = newProductFile.json.id
            } catch (e) {
              if (!isNew) {
                return this.$eventBus.setError('Beim hochladen der Datei ist ein Fehler unterlaufen. Bitte überprüfen Sie ihr Bild,' +
                  'oder melden sie sich bei einem Administrator.')
              } else {
                this.$eventBus.setError('Beim hochladen der Datei ist ein Fehler unterlaufen. Bitte überprüfen Sie ihr Bild,' +
                  'oder melden sie sich bei einem Administrator. Das Produkt wurde aber dennoch angelegt.')
                return await this.$router.push({
                  name: 'AdminProduct',
                  params: {
                    companyId: this.companyId,
                    categoryId: this.categoryId,
                    productId: product.id
                  }
                })
              }
            }
          } else {
            item.productFileId = possibilities[0].id
          }
        } else {
          item.productFileId = item.productFile.id
        }
        await this.$store.create('companies/categories/products/files', item, {
          companyId: this.companyId,
          productId: product.id,
          categoryId: this.categoryId
        })
      }
    },
    async changeVisibilityOfProduct(model: object) {
      try {
        await this.$store.update('companies/categories/products', model, {
          companyId: this.companyId,
          categoryId: this.categoryId
        })
        this.$vuetify.goTo(0)
        if (this.product.visible) {
          this.$eventBus.setSuccess('Sie haben erfolgreich das Produkt freigeschalten!')
        } else {
          this.$eventBus.setSuccess('Sie haben erfolgreich das Produkt versteckt!')
        }
      } catch (e) {
        if (this.product.visible) {
          this.$eventBus.setError('Das Produkt konnte nicht versteckt werden. Bitte melden Sie sich bei einem Administrator!')
        } else {
          this.$eventBus.setError('Das Produkt konnte nicht freigeschaltet werden. Bitte melden Sie sich bei einem Administrator!')
        }
      }
    },
    async saveFixFormats(productId: string) {
      try {
        for (let item of this.fixedFormats) {
          if (item.id) {
            await this.$store.update('companies/categories/products/fixed-formats', item, {
              companyId: this.companyId,
              productId: productId,
              categoryId: this.categoryId
            })
          } else {
            await this.$store.create('companies/categories/products/fixed-formats', item, {
              companyId: this.companyId,
              productId: productId,
              categoryId: this.categoryId
            })
          }
        }
      } catch (e) {
        this.$eventBus.setError('Beim Speichern der Festformate ist ein Fehler aufgetreten, ' +
          'bitte überprüfen Sie auf doppelte Einträge.')
      }
      this.fixedFormats = []
    },

    openWarningDialog(): void {
      if (this.product.visible) {
        this.$eventBus.openInfoDialog({
          warning: true,
          whiteColor: true,
          action: async () => {
            this.$eventBus.closeInfoDialog()
            this.changeVisibilityOfProduct(Object.assign(this.product, {visible: false}))
          },
          actionButton: 'Verstecken',
          actionButtonColor: 'warning',
          text: 'Möchten Sie wirklich das Produkt verstecken?'
        })
      } else {
        this.$eventBus.openInfoDialog({
          warning: true,
          whiteColor: true,
          action: () => {
            this.$eventBus.closeInfoDialog()
            this.changeVisibilityOfProduct(Object.assign(this.product, {visible: true}))
          },
          actionButton: 'Freischalten',
          actionButtonColor: 'warning',
          text: 'Möchten Sie wirklich das Produkt freischalten? Daraufhin wird das Produkt für alle sichtbar!'
        })
      }
    }
  }
})
