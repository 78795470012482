<template>
  <div class="total-center pa-4">
    <div>
      <v-progress-circular
        indeterminate
        :size="85"
        :width="3"
        class="primary--text"
      />
      <div class="bigger-text">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    name: 'Loading',
    props: {
      text: {
        type: String,
        default: 'Inhalt wird geladen, bitte warten...'
      }
    }
  })
</script>
