import { Model } from 'vue-powernap'

export default class File extends Model {
  get name () {
    return 'companies/categories/products/files'
  }

  url (params = {}) {
    return `/api/companies/${params.companyId}/product-categories/${params.categoryId}/products/${params.productId}/files`
  }

  get fields () {
    return {
      id: this.string(),
      description: this.string(),
      productId: this.string(),
      productFileId: this.string()
    }
  }

  get relationships () {
    return {
      product: this.hasOne('companies/categories/products', 'productId'),
      productFile: this.hasOne('product-files', 'productFileId')
    }
  }
}
