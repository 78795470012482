import { render, staticRenderFns } from "./ProductItemFiles.vue?vue&type=template&id=a63b97c0&scoped=true&"
import script from "./ProductItemFiles.vue?vue&type=script&lang=ts&"
export * from "./ProductItemFiles.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a63b97c0",
  null
  
)

export default component.exports