import { Model } from 'vue-powernap'

export default class Category extends Model {
  get name () {
    return 'companies/categories'
  }

  url (params = {}) {
    return `/api/companies/${params.companyId}/product-categories`
  }

  get fields () {
    return {
      id: this.string(),
      name: this.string(),
      position: this.integer(),
      visible: this.boolean(),
      companyId: this.string()
    }
  }

  get relationships () {
    return {
      company: this.hasOne('companies', 'companyId'),
      products: this.hasMany('companies/categories/products')
    }
  }
}
