import { Model } from 'vue-powernap'

export default class Product extends Model {
  get name () {
    return 'companies/categories/products'
  }

  url (params = {}) {
    return `/api/companies/${params.companyId}/product-categories/${params.categoryId}/products`
  }

  get fields () {
    return {
      id: this.string(),
      name: this.string(),
      text: this.string(),
      fileSlug: this.string(),
      showContactPersonBooking: this.boolean(),
      showContactPersonTech: this.boolean(),
      showUploadArea: this.boolean(),
      showText: this.boolean(),
      description: this.string(''),
      printMethod: this.string(''),
      printSpace: this.string(''),
      bleedFormat: this.string(''),
      adColumnWidth: this.float(0.0),
      adColumnSpace: this.float(0.0),
      adColumnNumber: this.integer(0),
      textColumnWidth: this.float(0.0),
      textColumnSpace: this.float(0.0),
      textColumnNumber: this.integer(0),
      counterpartPhone: this.string(''),
      counterpartFax: this.string(''),
      counterpartMail: this.string(''),
      counterpartNotes: this.string(''),
      counterpartBookingName: this.string(''),
      counterpartBookingPhone: this.string(''),
      counterpartBookingMail: this.string(''),
      position: this.integer(),
      visible: this.boolean(),
      categoryId: this.string()
    }
  }

  get relationships () {
    return {
      files: this.hasMany('companies/categories/products/files'),
      fixedFormats: this.hasMany('companies/categories/products/fixed-formats'),
      category: this.hasOne('companies/categories', 'categoryId')
    }
  }
}
