import {Extension} from '@tiptap/core'
import '@tiptap/extension-text-style'

type FontSizeOptions = {
  types: string[]
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    fontSize: {
      setFontSize: (size: string) => ReturnType
      unsetFontSize: () => ReturnType
    };
  }
}

export const FontSize = Extension.create<FontSizeOptions>({
  name: 'fontSize',

  addOptions(): FontSizeOptions {
    return {
      types: ['textStyle'],
    }
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontSize: {
            default: null,
            parseHTML: element => element.style.fontSize.replace(/['"]+/g, ''),
            renderHTML: attributes => {
              if (!attributes.fontSize) {
                return {}
              }
              const fontSize = attributes.fontSize
              const fontSizeWithUnit = fontSize.endsWith('pt') || fontSize.endsWith('px') || fontSize.endsWith('em')
                      ? fontSize
                      : `${fontSize}pt`
              const style = `font-size: ${fontSizeWithUnit}`
              return {
                style: style,
              }
            }
          }
        }
      }
    ]
  },

  addCommands() {
    return {
      setFontSize: fontSize => ({chain}) => {
        return chain()
                .setMark('textStyle', {fontSize})
                .run()
      },
      unsetFontSize: () => ({chain}) => {
        return chain()
                .setMark('textStyle', {fontSize: null})
                .removeEmptyTextStyle()
                .run()
      }
    }
  }
})
