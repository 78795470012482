
  import Vue from 'vue'

  import AppHeader from './AppHeader.vue'
  import AppFooter from './AppFooter.vue'
  import Loading from './Loading.vue'

  export default Vue.extend({
    name: 'Index',
    components: {
      AppHeader,
      AppFooter,
      Loading
    },
    data() {
      return {
        loading: false
      }
    },
    computed: {
      companies(): Array<any> {
        return this.$store.get('companies')
      }
    },

    async mounted(): Promise<void> {
      this.loading = true
      await this.$store.findAll('companies', {}, {
        params: {
          visible: true
        }
      })
      this.loading = false
    }
  })
