
import Vue from 'vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import ColorPickerMenu from '@/components/ColorPickerMenu.vue'

import {Editor, EditorContent} from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import {Color} from '@tiptap/extension-color'
import {FontSize} from '@/TipTapExtensions/FontSize'
import {TextAlign} from '@tiptap/extension-text-align'
import {Link} from '@tiptap/extension-link'
import {TextStyle} from '@tiptap/extension-text-style'
import {FontFamily} from '@tiptap/extension-font-family'
import {Underline} from '@tiptap/extension-underline'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import Image from '@tiptap/extension-image'


const TipTapExtensionTableCell = TableCell.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      backgroundColor: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.backgroundColor) {
            return {}
          }

          return {
            style: `background-color: ${attributes.backgroundColor}`,
          }
        },
        parseHTML: (element) => {
          return element.style.backgroundColor.replace(/['"]+/g, '')
        },
      },
    }
  },
})


export default Vue.extend({
  components: {
    ColorPickerMenu,
    EditorContent,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    value: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      editor: null as Editor | null,
      mulitpleButton: undefined as any,
      toggleButton: undefined as any,
      image: {} as object,
      urlDialog: false,
      url: '' as string
    }
  },
  computed: {
    font(): Array<any> {
      return [
        {
          text: 'Inter',
          callback: () => this.editor?.chain().focus().setFontFamily('Inter').run()
        },
        {
          text: 'Comic Sans',
          callback: () => this.editor?.chain().focus().setFontFamily('Comic Sans MS, Comic Sans').run()
        },
        {
          text: 'Serif',
          callback: () => this.editor?.chain().focus().setFontFamily('serif').run()
        },
        {
          text: 'Monospace',
          callback: () => this.editor?.chain().focus().setFontFamily('monospace').run()
        },
        {
          text: 'Cursive',
          callback: () => this.editor?.chain().focus().setFontFamily('cursive').run()
        },
        {
          text: 'Schriftart entfernen',
          callback: () => this.editor?.chain().focus().unsetFontFamily().run()
        }
      ]
    },
    fontSize(): Array<any> {
      return [
        {
          text: '8',
          callback: () => this.editor?.chain().focus().setFontSize('8').run()
        },
        {
          text: '9',
          callback: () => this.editor?.chain().focus().setFontSize('9').run()
        },
        {
          text: '10',
          callback: () => this.editor?.chain().focus().setFontSize('10').run()
        },
        {
          text: '11',
          callback: () => this.editor?.chain().focus().setFontSize('11').run()
        },
        {
          text: '12',
          callback: () => this.editor?.chain().focus().setFontSize('12').run()
        },
        {
          text: '14',
          callback: () => this.editor?.chain().focus().setFontSize('14').run()
        },
        {
          text: '16',
          callback: () => this.editor?.chain().focus().setFontSize('16').run()
        },
        {
          text: '18',
          callback: () => this.editor?.chain().focus().setFontSize('18').run()
        },
        {
          text: '20',
          callback: () => this.editor?.chain().focus().setFontSize('20').run()
        },
        {
          text: '22',
          callback: () => this.editor?.chain().focus().setFontSize('22').run()
        },
        {
          text: '24',
          callback: () => this.editor?.chain().focus().setFontSize('24').run()
        },
        {
          text: '26',
          callback: () => this.editor?.chain().focus().setFontSize('26').run()
        },
        {
          text: '28',
          callback: () => this.editor?.chain().focus().setFontSize('28').run()
        },
        {
          text: '36',
          callback: () => this.editor?.chain().focus().setFontSize('36').run()
        },
        {
          text: '48',
          callback: () => this.editor?.chain().focus().setFontSize('48').run()
        },
        {
          text: '72',
          callback: () => this.editor?.chain().focus().setFontSize('72').run()
        }
      ]
    },
    multipleBtn(): Array<any> {
      return [
        {
          icon: 'mdi-format-bold',
          value: 'bold',
          click: () => this.editor?.chain().focus().toggleBold().run()
        },
        {
          icon: 'mdi-format-italic',
          value: 'italic',
          click: () => this.editor?.chain().focus().toggleItalic().run()
        },
        {
          icon: 'mdi-format-underline',
          value: 'underline',
          click: () => this.editor?.chain().focus().toggleUnderline().run()
        },
        {
          icon: 'mdi-format-strikethrough-variant',
          value: 'strike',
          click: () => this.editor?.chain().focus().toggleStrike().run()
        },
        {
          icon: 'mdi-image',
          value: 'image',
          click: () => document.getElementById('input')?.click()
        }
      ]
    },
    toggleBtn(): Array<any> {
      return [
        {
          icon: 'mdi-link',
          value: 'link',
          click: () => this.handleLink()
        },
        {
          icon: 'mdi-format-list-numbered',
          value: 'orderedList',
          click: () => this.editor?.chain().focus().toggleOrderedList().run()
        },
        {
          icon: 'mdi-format-list-bulleted',
          value: 'bulletList',
          click: () => this.editor?.chain().focus().toggleBulletList().run()
        },
        {
          icon: 'mdi-format-align-left',
          value: 'textAlignLeft',
          click: () => this.editor?.chain().focus().setTextAlign('left').run()
        },
        {
          icon: 'mdi-format-align-center',
          value: 'textAlignCenter',
          click: () => this.editor?.chain().focus().setTextAlign('center').run()
        },
        {
          icon: 'mdi-format-align-right',
          value: 'textAlignRight',
          click: () => this.editor?.chain().focus().setTextAlign('right').run()
        },
        {
          icon: 'mdi-format-align-justify',
          value: 'textAlignJustify',
          click: () => this.editor?.chain().focus().setTextAlign('justify').run()
        }
      ]
    },
    btn(): Array<any> {
      return [
        {
          icon: 'mdi-undo',
          click: () => this.editor?.chain().focus().undo().run()
        },
        {
          icon: 'mdi-redo',
          click: () => this.editor?.chain().focus().redo().run()
        }
      ]
    },
    tblBtn(): Array<any> {
      return [
        {
          icon: 'mdi-table',
          tooltip: 'Tabelle einfügen',
          click: () => this.editor?.chain().focus().insertTable({rows: 3, cols: 3, withHeaderRow: true}).run()
        },
        {
          icon: 'mdi-table-column-plus-before',
          tooltip: 'Spalte davor einfügen',
          click: () => this.editor?.chain().focus().addColumnBefore().run()
        },
        {
          icon: 'mdi-table-column-plus-after',
          tooltip: 'Spalte danach einfügen',
          click: () => this.editor?.chain().focus().addColumnAfter().run()
        },
        {
          icon: 'mdi-table-column-remove',
          tooltip: 'Spalte löschen',
          click: () => this.editor?.chain().focus().deleteColumn().run()
        },
        {
          icon: 'mdi-table-row-plus-before',
          tooltip: 'Zeile davor einfügen',
          click: () => this.editor?.chain().focus().addRowBefore().run()
        },
        {
          icon: 'mdi-table-row-plus-after',
          tooltip: 'Zeile danach einfügen',
          click: () => this.editor?.chain().focus().addRowAfter().run()
        },
        {
          icon: 'mdi-table-row-remove',
          tooltip: 'Zeile löschen',
          click: () => this.editor?.chain().focus().deleteRow().run()
        },
        {
          icon: 'mdi-table-remove',
          tooltip: 'Tabelle löschen',
          click: () => this.editor?.chain().focus().deleteTable().run()
        },
        {
          icon: 'mdi-table-merge-cells',
          tooltip: 'Zellen zusammenführen',
          click: () => this.editor?.chain().focus().mergeCells().run()
        },
        {
          icon: 'mdi-table-split-cell',
          tooltip: 'Zellen teilen',
          click: () => this.editor?.chain().focus().splitCell().run()
        },
        {
          icon: 'mdi-format-title',
          tooltip: 'Zelle zu Überschrift',
          click: () => this.editor?.chain().focus().toggleHeaderCell().run()
        }
      ]
    }
  },

  watch: {
    value(value: string): void {
      const isSame = this.editor?.getHTML() === value
      if (isSame) {
        return
      }
      this.editor?.commands.setContent(value, false)
    },
    'editor.state'(): void {
      this.mulitpleButton = []
      this.toggleButton = null
      for (let mBtn of this.multipleBtn) {
        if (this.editor?.isActive(mBtn['value']) && !this.mulitpleButton.includes(mBtn['value'])) {
          this.mulitpleButton.push(mBtn['value'])
        }
      }
      for (let tBtn of this.toggleBtn) {
        if (this.editor?.isActive(tBtn['value']) && this.toggleButton !== tBtn['value']) {
          this.toggleButton = tBtn['value']
        }
      }
    },
  },

  mounted(): void {

    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        TextAlign.configure({
          types: ['heading', 'paragraph']
        }),
        Link.configure({
          validate: href => /^https?:\/\//.test(href),
        }),
        TextStyle,
        FontFamily,
        Underline,
        FontSize,
        TipTapExtensionTableCell,
        Color.configure({
          types: ['textStyle'],
        }),
        Table.configure({
          resizable: true,
          HTMLAttributes: {
            class: 'customTable'
          }
        }),
        TableRow,
        TableHeader,
        Image
      ],
      editable: true,
      onUpdate: () => {
        if (this.editor?.getHTML() != undefined) {
          this.$emit('input', this.editor?.getHTML())
        }
      },
    })
  },

  beforeDestroy(): void {
    this.editor?.destroy()
  },

  methods: {
    handleLink(): void {
      const link = this.editor?.getAttributes('link').href

      if (link) {
        this.url = link
      } else {
        this.url = ''
      }
      this.urlDialog = true
    },
    async confirmLink(url: string, invalid: boolean): Promise<void> {
      if (!invalid) {
        if (url === '') {
          this.editor?.chain()
            .focus()
            .extendMarkRange('link')
            .unsetLink()
            .run()
        } else {
          this.editor?.chain()
            .focus()
            .extendMarkRange('link')
            .setLink({href: url})
            .run()
        }
        this.urlDialog = false
      }
    },
    saveImage($event?: DragEvent) {
      const input = document.getElementById('input') as HTMLInputElement
      input.files = $event?.dataTransfer?.files!

      if (input.files.length > 0) {
        const fileUrl = URL.createObjectURL(input.files[0])
        this.editor?.chain().focus().setImage({src: fileUrl}).run()

        let formData = new FormData()
        formData.append('file', input.files[0])
        this.$emit('input-file', formData)
      }
    }
  }
})


