
import Vue from 'vue'

export default Vue.extend({
  name: 'ColorPickerMenu',
  props: {
    value: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      menu: false,
      color: ''
    }
  },

  watch: {
    value(value) {
      this.color = value
    }
  },
  methods: {
    saveColorSelection() {
      this.$emit('input', this.color)
      this.menu = false
    },
    cancelColorSelection() {
      this.color = this.value
      this.menu = false
    }
  }
})
