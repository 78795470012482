import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import de from 'vuetify/src/locale/de'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0071AA',
        accent: '#0071AA',
        secondary: '#40393b',
        info: '#2ba8ff',
        warning: '#ffb62f',
        error: '#c80f13',
        success: '#0bcf35'
      }
    }
  },
  lang: {
    locales: {
      de
    },
    current: 'de'
  },
  icons: {
    iconfont: 'mdi'
  }
})
