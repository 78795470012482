
  import Vue from 'vue'

  export default Vue.extend({
    name: 'Breadcrumbs',
    props: {
      route: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        routes: [] as Array<object>
      }
    },

    mounted(): void {
      const routes = []
      for (const matched of this.route.matched) {
        let hash = ''
        if (matched.name === this.route.name) {
          hash = this.route.hash
        }
        if (matched.meta.bcDynamic) {
          const val = matched.meta.breadcrumb(this.route)
          routes.push({name: val.name, path: matched.name, params: this.route.params, hash})
        } else {
          if (matched.meta.breadcrumb) {
            routes.push({name: matched.meta.breadcrumb(this.route), path: matched.name, hash})
          }
        }
      }
      this.routes = routes
    }
  })
