
  import Vue from 'vue'
  import Loading from './Loading.vue'

  import draggable from 'vuedraggable'

  export default Vue.extend({
    name: 'CardsList',
    components: {
      draggable,
      Loading
    },
    model: {
      prop: 'models', // set v-model to models instead of value
      event: 'change'
    },
    props: {
      models: {
        type: [Array, Object]
      },
      textAttr: {
        type: String,
        required: true
      },
      header: {
        type: String,
        required: true
      },
      sortable: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  })
