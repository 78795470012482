
import Vue from 'vue'

import ProductFixedFormat from './ProductFixedFormat.vue'
import TextAdTable from './TextAdTable.vue'

export default Vue.extend({
  name: 'Format',
  components: {
    ProductFixedFormat,
    TextAdTable
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  mounted() {
    let cells = document.getElementsByTagName('td')
    for (let i = 0; i < cells.length; i++) {
      let colWidth = cells[i].getAttribute('colwidth')
      if (colWidth) {
        cells[i].style.width = colWidth + 'px'
      }
    }
  }
})
