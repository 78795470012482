import { Model } from 'vue-powernap'

export default class Link extends Model {
  get name () {
    return 'links'
  }

  url (params = {}) {
    return `/api/links`
  }

  get fields () {
    return {
      id: this.integer(),
      name: this.string(),
      link: this.string()
    }
  }
}
