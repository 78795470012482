
  import Vue from 'vue'

  import CardsList from '../CardsList.vue'
  import CategoryDialog from '../CategoryDialog.vue'
  import Loading from '../Loading.vue'

  export default Vue.extend({
    name: 'AdminCategory',
    components: {
      CardsList,
      CategoryDialog,
      Loading
    },
    props: {
      categoryId: {
        required: true,
        type: String
      },
      companyId: {
        required: true,
        type: String
      }
    },
    data() {
      return {
        loading: true
      }
    },
    computed: {
      category(): Model<any> {
        return this.$store.get('companies/categories', this.categoryId, {companyId: this.companyId})
      }
    },

    async mounted(): Promise<void> {
      this.loading = true
      await this.$store.find('companies', this.companyId)
      await this.$store.findAll('companies/categories', {
        companyId: this.companyId
      })
      await this.$store.findAll('companies/categories/products', {
        companyId: this.companyId,
        categoryId: this.categoryId
      })
      this.loading = false
    },
    methods: {
      async save(category: Model<any>): Promise<void> {
        this.loading = true
        try {
          await this.$store.update('companies/categories', category, {
            companyId: this.companyId
          })
          this.$eventBus.setSuccess('Sie haben erfolgreich die Kategorie geändert.')
        } catch (e) {
          this.$eventBus.setError('Beim ändern der Kategorie ist ein Fehler aufgetreten, bitte überprüfen Sie ihre Daten.')
        }
        this.loading = false
      },
      async deleteCategory(category: Model<any>): Promise<void> {
        try {
          await this.$store.delete('companies/categories', category.id, {
            companyId: this.companyId
          })
          await this.$router.replace('/admin/' + this.companyId)
          this.$eventBus.setSuccess('Sie haben erfolgreich die Kategorie gelöscht!')
        } catch (e) {
          this.$eventBus.setError('Die Kategorie konnte nicht gelöscht werden, bitte melden Sie sich bei einem Administrator.')
        }
      },
      onEnd(event: any): Model<any> {
        return this.$store.update(
          'companies/categories/products',
          Object.assign(
            this.category.products[event.newIndex],
            {position: event.newIndex + 1}
          ),
          {
            companyId: this.companyId,
            categoryId: this.categoryId
          }
        )
      },
      openWarningDialog(): void {
        if (this.category.visible) {
          this.$eventBus.openInfoDialog({
            warning: true,
            whiteColor: true,
            action: async () => {
              this.$eventBus.closeInfoDialog()
              await this.save(Object.assign({}, this.category, {visible: false}))
              await this.$store.findAll('companies/categories/products', {
                companyId: this.companyId,
                categoryId: this.categoryId
              })
            },
            actionButton: 'Verstecken',
            actionButtonColor: 'warning',
            text: 'Möchten Sie wirklich die Kategorie verstecken? Alle zugehörigen Produkte ' +
              'werden ebenfalls versteckt!'
          })
        } else {
          this.$eventBus.openInfoDialog({
            warning: true,
            whiteColor: true,
            action: async () => {
              this.$eventBus.closeInfoDialog()
              await this.save(Object.assign({}, this.category, {visible: true}))
              await this.$store.findAll('companies/categories/products', {
                companyId: this.companyId,
                categoryId: this.categoryId
              })
            },
            actionButton: 'Freischalten',
            actionButtonColor: 'warning',
            text: 'Möchten Sie wirklich die Kategorie freischalten? Daraufhin wird diese mit ihren Produkten für alle sichtbar!'
          })
        }
      }
    }
  })
