
  import Vue from 'vue'
  import Loading from './Loading.vue'

  export default Vue.extend({
    name: 'ColorProfiles',
    components: {
      Loading
    },
    data() {
      return {
        headers: [
          {
            text: 'Farbprofile',
            value: 'name',
            sortable: false
          },
          {
            text: 'Publikation',
            value: 'products',
            sortable: false
          }
        ],
        loading: true
      }
    },
    computed: {
      colorProfiles(): Array<object> {
        return this.$store.get('product-files')
      }
    },
    async mounted() {
      this.loading = true
      await this.$store.findAll('product-files', {}, {
        params: {
          type: 'color-profile'
        },
        headers: {
          'Accept': 'application/vnd.extended+json'
        }
      })
      this.loading = false
    }
  })
