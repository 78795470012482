
  import Vue from 'vue'

  import CompanyDialog from '../CompanyDialog.vue'
  import Loading from '../Loading.vue'

  export default Vue.extend({
    name: 'AdminIndex',
    components: {
      CompanyDialog,
      Loading
    },
    data() {
      return {
        drawer: true,
        openCompanyDialog: false,
        loading: false,
        fab: false
      }
    },
    computed: {
      companies(): Array<Model<any>> {
        return this.$store.state.companies
      }
    },
    async mounted(): Promise<void> {
      this.loading = true
      await this.$store.findAll('companies')
      this.loading = false
    },
    methods: {
      async save(company: Model<any>): Promise<void> {
        try {
          await this.$store.create('companies', company)
          this.$eventBus.setSuccess('Sie haben erfolgreich das Unternehmen angelegt.')
        } catch (error: any) {
          if (error.response.status === 409) {
            this.$eventBus.setError('Die Firma existiert bereits, bitte wählen Sie einen anderen Namen!')
          } else {
            this.$eventBus.setError('Es ist ein Fehler aufgetreten, bitte überprüfen Sie ihre Daten.')
          }
        }
      },

      openInfoDialog(): void {
        this.$eventBus.openInfoDialog({
          maxWidth: 400,
          closeButton: null,
          info: true,
          whiteColor: true,
          actionButton: 'Schließen',
          actionButtonColor: 'info',
          title: 'Information',
          text: 'Um die Unternehmen zu sortieren, ist es möglich, die Karten der Unternehmen per Drag and Drop ' +
            'an die gewünschte Position zu verschieben.'
        })
      }
    }
  })
