
  import Vue from 'vue'

  export default Vue.extend({
    name: 'ProductFileInput',
    props: {
      value: {
        type: [Object],
        default: null
      },
      accept: {
        type: String,
        default: '*'
      },
      label: {
        type: String,
        default: 'Datei auswählen'
      },
      required: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        filename: '',
        model: {} as any,
        fileValidation: [
          (v: any) => {
            if (!v) return 'Bitte eine Datei auswählen'

            return true // file is valid
          }
        ]
      }
    },
    watch: {
      value(v: any): void {
        this.model = v
      }
    },
    mounted(): void {
      this.model = this.value
    },
    methods: {
      onFocus(): void {
        if (!this.disabled) {
          (this.$refs.fileInput as HTMLInputElement).click()
        }
      },
      onFileChange($event: DragEvent): void {
        const files = ($event.target as HTMLInputElement).files || ($event.dataTransfer as DataTransfer).files
        if (files.length > 0) {
          this.model.name = this.setFileName(files)
          this.model.file = files[0]
        } else {
          this.model.name = ($event.target as HTMLInputElement).value.split('\\').pop()
        }
        this.$emit('input', this.model)
      },
      setFileName(files: FileList): any {
        let f = files
        if (f.length > 0) {
          return [...f].map(file => file.name).join(', ')
        }
      }
    }
  })
