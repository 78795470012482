
  import Vue from 'vue'

  import CardsList from '../CardsList.vue'
  import CategoryDialog from '../CategoryDialog.vue'
  import CompanyDialog from '../CompanyDialog.vue'
  import Loading from '../Loading.vue'

  export default Vue.extend({
    name: 'AdminCompany',
    components: {
      CardsList,
      CategoryDialog,
      CompanyDialog,
      Loading
    },
    props: {
      companyId: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        loading: true,
        message: '',
        error: false
      }
    },
    computed: {
      company(): Model<any> {
        return this.$store.get('companies', this.companyId)
      }
    },
    async mounted(): Promise<void> {
      this.loading = true
      await this.$store.find('companies', this.companyId)
      await this.$store.findAll('companies/categories', {
        companyId: this.companyId
      })
      this.loading = false
    },
    methods: {
      async saveCategory(category: Model<any>): Promise<void> {
        try {
          await this.$store.create('companies/categories', category, {
            companyId: this.companyId
          })
          this.$eventBus.setSuccess('Sie haben erfolgreich eine Kategorie hinzugefügt.')
        } catch (error: any) {
          if (error.response.status === 409) {
            this.$eventBus.setError('Die Kategorie existiert bereits, bitte wählen Sie einen anderen Namen!')
          } else {
            this.$eventBus.setError('Es ist ein Fehler aufgetreten, bitte überprüfen Sie ihre Daten.')
          }
        }
      },
      onEnd(event: any): Model<any> {
        const category = Object.assign(this.company.categories[event.newIndex], {position: event.newIndex + 1})
        return this.$store.update('companies/categories', category, {
          companyId: this.companyId
        })
      },
      async save(company: Model<any>): Promise<void> {
        this.loading = true
        try {
          await this.$store.update('companies', company)
          this.$eventBus.setSuccess('Sie haben das Unternehmen erfolgreich geändert.')
        } catch (error: any) {
          if (error.response.status === 409) {
            this.$eventBus.setError('Das Unternehmen existiert bereits, bitte wählen Sie einen anderen Namen!')
          } else {
            this.$eventBus.setError('Es ist ein Fehler aufgetreten, bitte überprüfen Sie ihre Daten.')
          }
        }
        this.loading = false
      },
      openWarningDialog(): void {
        if (this.company.visible) {
          this.$eventBus.openInfoDialog({
            warning: true,
            whiteColor: true,
            action: async () => {
              this.$eventBus.closeInfoDialog()
              await this.save(Object.assign({}, this.company, {visible: false}))
              await this.$store.findAll('companies/categories', {
                companyId: this.companyId
              })
            },
            actionButton: 'Verstecken',
            actionButtonColor: 'warning',
            text: 'Möchten Sie wirklich das Unternehmen verstecken? Alle zugehörigen Kategorien und deren Produkte ' +
              'werden ebenfalls versteckt!'
          })
        } else {
          this.$eventBus.openInfoDialog({
            warning: true,
            whiteColor: true,
            action: async () => {
              this.$eventBus.closeInfoDialog()
              await this.save(Object.assign({}, this.company, {visible: true}))
              await this.$store.findAll('companies/categories', {
                companyId: this.companyId
              })
            },
            actionButton: 'Freischalten',
            actionButtonColor: 'warning',
            text: 'Möchten Sie wirklich das Unternehmen freischalten? Daraufhin wird das Unternehmen inklusiver ' +
              'Kategorien und deren Produkte für alle sichtbar!'
          })
        }
      }
    }
  })
