export default class Store {
  constructor() {
    this._models = {}
    this.state = {}
    this.actions = {}
    this.defaultHeaders = {
      'Content-Type': 'application/json'
    }
    this.defaultURLParams = {}
    try {
      this.signalController = new AbortController()
    } catch (e) {
      this.signalController = null
    }

    if (typeof window !== 'undefined' && window.Vue) {
      this.install(window.Vue)
    }
  }

  install(Vue, options) {
    Vue.prototype.$store = this

    new Vue({
      data: this.state
    })
  }

  register(model) {
    model._store = this
    if (!model._parent) {
      this.state[model.name] = []
    }

    this._models[model.name] = model
  }

  addAction(name, func) {
    this.actions[name] = func
  }

  call(name, options) {
    return this.actions[name](options)
  }

  _model(name) {
    return this._models[name]
  }

  parseHeaders(model, headers) {
    return new Headers(Object.assign({}, this.defaultHeaders, {'Accept': model.mediaType}, headers))
  }

  setRelations(object, model, parent, isNew = false, cleanup = false) {
    const relationships = model.relationships
    for (const key in relationships) {
      if (!relationships.hasOwnProperty(key)) {
        continue
      }

      const meta = relationships[key]
      const relationModelName = meta.modelName
      const relationModel = this._models[relationModelName]
      const relationRelationships = relationModel.relationships
      for (const relationKey in relationRelationships) {
        if (
          !relationRelationships.hasOwnProperty(relationKey) ||
          (relationModel.hasParent && relationModel._parent.name === model.name) ||
          relationRelationships[relationKey].modelName !== model.name
        ) {
          continue
        }

        if (relationRelationships[relationKey].type === 'hasOne') {
          const relationParents = []
          if (relationModel.hasParent) {
            relationParents.push(relationModel._parent)
            let relationParent = relationParents[0]
            // Laden der übergeordneten Models, falls es welche gibt und fügt
            // Sie in eine Liste vom obersten bis nach untersten Sortiert.
            while (relationParent.hasParent) {
              relationParent = relationParent._parent
              relationParents.unshift(relationParent)
            }
          }
          for (let relation of this.findRelation(
            relationModel,
            relationRelationships[relationKey].foreignKey,
            model.primaryKey,
            object,
            relationParents,
            this.state
          )
            ) {
            if (relation) {
              relation[relationKey] = object
              const idx = object[key].findIndex(item => item[relationModel.primaryKey] === relation[relationModel.primaryKey])
              if (idx === -1) {
                object[key].push(relation)
              } else {
                if (cleanup) {
                  relation[relationKey] = {}
                  object[key].splice(
                    idx,
                    1
                  )
                } else {
                  object[key].splice(
                    idx,
                    1,
                    relation
                  )
                }
              }
            } else {
              relation[relationKey] = {}
            }
          }
        } else {
          let relation
          if (model.hasParent && model._parent.name === relationModel.name) {
            relation = parent[relationKey].find(
              item => item[relationModel.primaryKey] === object[meta.foreignKey]
            )
            if (relation && relation.hasOwnProperty(relationKey) && !isNew) {
              const idx = relation[relationKey].findIndex(item => item[model.primaryKey] === object[model.primaryKey])
              if (idx === -1) {
                relation[relationKey].push(object)
              } else {
                if (cleanup) {
                  relation[relationKey].splice(
                    idx,
                    1
                  )
                } else {
                  relation[relationKey].splice(
                    idx,
                    1,
                    object
                  )
                }
              }
            }
          } else {
            const relationParents = []
            if (relationModel.hasParent) {
              relationParents.push(relationModel._parent)
              let relationParent = relationParents[0]
              // Laden der übergeordneten Models, falls es welche gibt und fügt
              // Sie in eine Liste vom obersten bis nach untersten Sortiert.
              while (relationParent.hasParent) {
                relationParent = relationParent._parent
                relationParents.unshift(relationParent)
              }
            }
            for (let relation of this.findRelation(
              relationModel,
              relationModel.primaryKey,
              meta.foreignKey,
              object,
              relationParents,
              this.state
            )
              ) {
              if (relation && relation.hasOwnProperty(relationKey) && !isNew) {
                const idx = relation[relationKey].findIndex(item => item[model.primaryKey] === object[model.primaryKey])
                if (idx === -1) {
                  relation[relationKey].push(object)
                } else {
                  if (cleanup) {
                    relation[relationKey].splice(
                      idx,
                      1
                    )
                  } else {
                    relation[relationKey].splice(
                      idx,
                      1,
                      object
                    )
                  }
                }
              }
            }
          }
        }
      }

      if (meta.type === 'hasOne') {
        if (model.hasParent && relationModel.name === model._parent.name) {
          object[key] = parent
        } else {
          const relationParents = []
          if (relationModel.hasParent) {
            relationParents.push(relationModel._parent)
            let relationParent = relationParents[0]
            // Laden der übergeordneten Models, falls es welche gibt und fügt
            // Sie in eine Liste vom obersten bis nach untersten Sortiert.
            while (relationParent.hasParent) {
              relationParent = relationParent._parent
              relationParents.unshift(relationParent)
            }
          }
          for (let relation of this.findRelation(
            relationModel,
            relationModel.primaryKey,
            meta.foreignKey,
            object,
            relationParents,
            this.state
          )
            ) {
            if (relation) {
              object[key] = relation
              const relationKey = Object.entries(relationRelationships).find(item => item[1].modelName === model.name)
              if (relationKey) {
                const relationRelationModel = this._models[relationKey[1].modelName]
                const idx = relation[relationKey[0]].findIndex(
                  item => item[relationRelationModel.primaryKey] === object[relationRelationModel.primaryKey]
                )
                if (idx === -1) {
                  relation[relationKey[0]].push(object)
                } else {
                  if (cleanup) {
                    object[key] = {}
                    relation[relationKey[0]].splice(
                      idx,
                      1
                    )
                  } else {
                    relation[relationKey[0]].splice(
                      idx,
                      1,
                      object
                    )
                  }
                }
              }
            } else {
              object[key] = {}
            }
          }
        }
      }
    }
  }

  getParent(model, params) {
    if (model.hasParent) {
      let parent = model._parent
      const meta = Object.values(model.relationships).find(
        item => item.modelName === parent.name
      )

      return this.getParent(parent, params)[!parent.hasParent ? parent.name : parent._parentKey].find(
        item => item[parent.primaryKey] === params[meta.foreignKey]
      )
    } else {
      return this.state
    }
  }

  * findRelation(model, foreignKey, primaryKey, object, parents, parent) {
    /*
     Sucht ob die Id des übergebenen Objektes in anderen Objekten als Foreignkey existiert
     und gibt die Relation dann zurück.
     */
    parents = parents.slice(0)
    if (parents.length === 0) {
      yield* parent[model._parentKey || model.name].filter(item => item[foreignKey] === object[primaryKey])
    } else {
      let nextModel = parents.shift()

      for (let item of parent[nextModel._parentKey || nextModel.name]) {
        yield* this.findRelation(model, foreignKey, primaryKey, object, parents, item)
      }
    }
  }

  async findAll(name, params = {}, options = {}) {
    try {
      this.signalController = new AbortController()
    } catch (e) {
      this.signalController = null
    }
    options = Object.assign({reload: true}, options)
    const model = this._model(name)
    const url = model.listUrl(params, Object.assign({}, this.defaultURLParams, options.params))

    if (!options.reload && model.lastRequestUrl === url) {
      return this.get(name, null, params)
    } else {
      model.lastRequestUrl = url
    }

    const response = await fetch(
      url,
      {
        headers: this.parseHeaders(model, options.headers),
        signal: this.signalController ? this.signalController.signal : null
      }
    )

    if (response.ok) {
      let json = await response.json()
      const parent = this.getParent(model, params)
      json = json.map(item => model.deserialize(item))
      for (let i = 0; i < json.length; i++) {
        const object = json[i]
        this.setRelations(object, model, parent)
      }

      parent[model._parentKey || name] = json

      response.json = json // Override json => this property cannot be read more than once
    }

    return response
  }

  async find(name, id, params = {}, options = {}) {
    try {
      this.signalController = new AbortController()
    } catch (e) {
      this.signalController = null
    }
    options = Object.assign({reload: true}, options)
    const model = this._model(name)
    const url = model.itemUrl(id, params, Object.assign({}, this.defaultURLParams, options.params))

    if (!options.reload && model.lastRequestUrl === url) {
      return this.get(name, id, params)
    } else {
      model.lastRequestUrl = url
    }

    const response = await fetch(
      url,
      {
        headers: this.parseHeaders(model, options.headers),
        signal: this.signalController ? this.signalController.signal : null
      }
    )

    if (response.ok) {
      let json = await response.json()
      json = model.deserialize(json)
      const parent = this.getParent(model, params)
      this.setRelations(json, model, parent)

      const idx = parent[model._parentKey || name].findIndex(item => item[model.primaryKey] === id)
      if (idx === -1) {
        parent[model._parentKey || name].push(json)
      } else {
        parent[model._parentKey || name].splice(
          idx,
          1,
          json
        )
      }

      response.json = json // Override json => this property cannot be read more than once
    }

    return response
  }

  async create(name, data = {}, params = {}, options = {}) {
    const model = this._model(name)
    const response = await fetch(model.listUrl(params, options.params), {
      method: 'POST',
      body: JSON.stringify(model.serialize(data)),
      headers: this.parseHeaders(model, options.headers)
    })
    if (response.ok) {
      const text = await response.text()
      if (text) {
        let json = JSON.parse(text)
        json = model.deserialize(json)
        const parent = this.getParent(model, params)
        this.setRelations(json, model, parent, true)
        parent[model._parentKey || name].push(json)

        response.json = json // Override json => this property cannot be read more than once
      } else {
        response.json = null
      }

      return response
    } else {
      throw response
    }
  }

  new(name, data = {}) {
    const model = this._model(name)
    data = model.deserialize(data)

    return Object.assign({}, data)
  }

  async update(name, data = {}, params = {}, options = {}) {
    const model = this._model(name)
    const response = await fetch(model.itemUrl(data[model.primaryKey], params, options.params), {
      method: 'PUT',
      body: JSON.stringify(model.serialize(data)),
      headers: this.parseHeaders(model, options.headers)
    })
    if (response.ok) {
      let json = await response.json()
      const parent = this.getParent(model, params)
      const idx = parent[model._parentKey || name].findIndex(item => item[model.primaryKey] === json[model.primaryKey])
      json = Object.assign({}, parent[model._parentKey || name][idx], json)
      json = model.deserialize(json)
      this.setRelations(json, model, parent)

      if (idx === -1) {
        parent[model._parentKey || name].push(json)
      } else {
        parent[model._parentKey || name].splice(
          idx,
          1,
          json
        )
      }

      response.json = json // Override json => this property cannot be read more than once

      return response
    } else {
      throw response
    }
  }

  async updateAll(name, data = {}, params = {}, options = {}) {
    const model = this._model(name)
    const response = await fetch(model.listUrl(params, options.params), {
      method: 'PUT',
      body: JSON.stringify(model.serialize(data)),
      headers: this.parseHeaders(model, options.headers)
    })
    if (response.ok) {
      let json = await response.json()

      const parent = this.getParent(model, params)

      for (let object of json) {
        const idx = parent[model._parentKey || name].findIndex(item => item[model.primaryKey] === json[model.primaryKey])
        object = Object.assign({}, parent[model._parentKey || name][idx], object)
        object = model.deserialize(object)
        this.setRelations(object, model, parent)

        if (idx === -1) {
          parent[model._parentKey || name].push(object)
        } else {
          parent[model._parentKey || name].splice(
            idx,
            1,
            object
          )
        }
      }

      response.json = json // Override json => this property cannot be read more than once

      return response
    } else {
      throw response
    }
  }

  async delete(name, id, params = {}, options = {}) {
    const model = this._model(name)
    const response = await fetch(model.itemUrl(id, params, options.params), {
      method: 'DELETE',
      headers: this.parseHeaders(model, options.headers)
    })
    if (response.ok) {
      const parent = this.getParent(model, params)
      const idx = parent[model._parentKey || model.name].findIndex(item => item[model.primaryKey] === id)
      if (idx >= 0) {
        this.setRelations(parent[model._parentKey || model.name][idx], model, parent, false, true)
      }
      parent[model._parentKey || model.name].splice(
        idx,
        1
      )

      return response
    } else {
      throw response
    }
  }

  async deleteAll(name, data = {}, params = {}, options = {}) {
    /*
     this method can not update the state, use delete with an id or reload the model after delete all
     */
    const model = this._model(name)
    const response = await fetch(model.listUrl(params, options.params), {
      method: 'DELETE',
      body: JSON.stringify(data),
      headers: this.parseHeaders(model, options.headers)
    })
    if (response.ok) {
      return response
    } else {
      throw response
    }
  }

  get(name, id = null, params = {}) {
    try {
      const model = this._model(name)
      const parent = this.getParent(model, params)
      return id && parent[model._parentKey || model.name] ? parent[model._parentKey || model.name].find(item => item.id === id) || {}
        : parent[model._parentKey || model.name] || []
    } catch (e) {
      throw new Error(`Cannot find model: ${name}. Please check the name or if the parent is loaded.`)
    }
  }
}
