
  import Vue from 'vue'
  import CompanyTabs from './CompanyTabs.vue'
  import Loading from './Loading.vue'

  export default Vue.extend({
    name: 'Company',
    components: {
      CompanyTabs,
      Loading
    },
    props: {
      companyId: {
        type: [String, Number],
        required: true
      }
    },
    data() {
      return {
        isEmpty: false,
        loading: true
      }
    },
    computed: {
      company(): Model<any> {
        return this.$store.get('companies', this.companyId)
      }
    },
    watch: {
      '$route'(): void {
        this.loadPreview()
      }
    },
    async mounted(): Promise<void> {
      await this.$store.findAll('product-files')

      const response = await this.$store.find('companies', this.companyId)
      if (response.status === 404) {
        this.$router.push('/')
        return
      }

      await this.$store.findAll('companies/categories', {
        companyId: this.companyId
      }, {
        params: {
          visible: true
        }
      })

      for (const category of this.company.categories) {
        await this.$store.findAll('companies/categories/products', {
          companyId: this.companyId,
          categoryId: category.id
        }, {
          params: {
            visible: true
          }
        })
      }
      this.company.categories = this.company.categories.filter((category: Model<any>) => category.products.length > 0)
      await this.loadPreview()
      this.loading = false
      this.isEmpty = this.company.categories.length === 0
    },

    methods: {
      async loadPreview(): Promise<void> {
        if (this.$route.hash) {
          const hash = this.$route.hash.substring(1).split('.')
          const categoryId = hash[0]
          const productId = hash[1]

          let category = this.company.categories.find((item: Model<any>) => item.id === categoryId)
          if (!category) {
            this.loading = true
            await this.$store.find('companies/categories', categoryId, {
              companyId: this.companyId
            })
            category = this.$store.get('companies/categories', categoryId, {companyId: this.companyId})
          }
          if (category && !category.products.find((item: Model<any>) => item.id === productId)) {
            this.loading = true
            try {
              await this.$store.find('companies/categories/products', productId, {
                categoryId: categoryId,
                companyId: this.companyId
              })
            } catch (e) {
              if (category.products.length === 0) {
                this.company.categories.splice(this.company.categories.length - 1)
                this.$router.push('/')
              }
            }
            this.loading = false
          }
        }
      }
    }
  })
