
  import Vue from 'vue'
  import Loading from './Loading.vue'
  import CompanyImageUpload from './CompanyImageUpload.vue'

  export default Vue.extend({
    name: 'CompanyDialog',
    components: {
      Loading,
      CompanyImageUpload
    },
    props: {
      company: {
        type: Object,
        default(): Model<any> | null {
          return this.$store.new('companies')
        }
      },
      noDelete: {
        type: Boolean,
        default: false
      },
      open: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        dialog: false,
        companyCopy: {} as any,
        activeFile: null,
        inactiveFile: null
      }
    },
    watch: {
      open(value: boolean): void {
        this.dialog = value
      }
    },
    mounted(): void {
      this.companyCopy = Object.assign({}, this.company)
    },
    methods: {
      async save(): Promise<void> {
        const valid = await this.$validator.validateAll()
        if (valid) {
          if (this.activeFile) {
            await this.saveFile('logoActive', this.activeFile)
          }
          if (this.inactiveFile) {
            await this.saveFile('logoInactive', this.inactiveFile)
          }

          this.$emit('save', this.companyCopy)
          this.close()
        }
      },

      async deleteCompany(): Promise<void> {
        this.$eventBus.closeInfoDialog()
        try {
          await this.$store.delete('companies', this.companyCopy.id)
          this.$eventBus.setSuccess('Sie haben erfolgreich das Unternehmen gelöscht.')
        } catch (e) {
          this.$eventBus.setError('Beim Löschen des Unternehmens ist ein Fehler aufgetreten. Bitte melden Sie' +
            ' sich bei einem Administrator.')
        }
        this.close()
        await this.$router.replace('/admin')
      },

      openWarningDialog(): void {
        this.dialog = false
        this.$eventBus.openInfoDialog({
          error: true,
          whiteColor: true,
          action: this.deleteCompany,
          text: 'Möchten Sie wirklich die Firma löschen? Alle zugehörigen Kategorien und deren Produkte ' +
            'werden ebenfalls gelöscht!'
        })
      },

      close(): void {
        this.dialog = false
        this.$validator.reset()
        this.companyCopy = Object.assign({}, this.$store.get('companies', this.company.id))
        this.activeFile = null
        this.inactiveFile = null
        this.$emit('close')
      },

      async saveFile(name: string, file: any): Promise<void> {
        let formData = new FormData()
        formData.append('file', file)
        try {
          let picture = null
          if (this.companyCopy[`${name}Id`]) {
            picture = await this.$store.call('update-file', {
              id: this.companyCopy[`${name}Id`],
              data: formData
            })
          } else {
            picture = await this.$store.call('upload-file', {
              data: formData
            })
          }
          this.companyCopy[`${name}Id`] = picture.id
          this.companyCopy[`${name}`] = picture.file
        } catch (e) {
          this.$eventBus.setError(`Beim hochladen der Datei ${file.name} ist ein Fehler aufgetreten. Bitte überprüfen Sie ihre Datei
         oder melden Sie sich bei einem Administrator.`)
        }
      }
    }
  })
