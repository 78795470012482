
  import Vue from 'vue'

  export default Vue.extend({
    inject: ['$validator'],
    name: 'CompanyImageUpload',
    props: {
      value: {
        type: String,
        default: null
      },
      label: {
        type: String,
        default: 'Datei auswählen'
      }
    },
    data() {
      return {
        url: ''
      }
    },
    watch: {
      value(v: string) {
        this.url = v
      }
    },
    mounted(): void {
      this.url = this.value
    },
    methods: {
      onFocus() {
        (this.$refs.fileInput as any).click()
      },
      onFileChange($event: DragEvent) {
        const files = ($event.target as HTMLInputElement).files || ($event.dataTransfer as DataTransfer).files
        if (files.length > 0) {
          this.url = URL.createObjectURL(files[0])
          this.$emit('fileChange', files[0])
        } else {
          this.url = ''
          this.$emit('fileChange', null)
        }

        this.$emit('input', this.url)
      }
    }
  })
