
  import Vue from 'vue'
  import draggable from 'vuedraggable'

  import CompanyCard from './CompanyCard.vue'

  export default Vue.extend({
    name: 'CompanyList',
    components: {
      CompanyCard,
      draggable
    },
    props: {
      companies: {
        type: Array,
        required: true
      },
      height: {
        type: String,
        required: true
      },
      sortable: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data() {
      return {
        drag: false
      }
    },

    methods: {
      onEnd(event: any): void {
        this.drag = false
        return this.$store.update('companies', Object.assign(this.companies[event.newIndex], {position: event.newIndex + 1}))
      }
    }
  })
