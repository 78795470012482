import Vue from 'vue'
import Router, {Route} from 'vue-router'
import cookie from 'cookie'
import store from '../models/index'

import AdminColorProfiles from '../components/admin/files/ColorProfiles.vue'
import AdminDataSheets from '../components/admin/files/DataSheets.vue'
import AdminPriceLists from '../components/admin/files/PriceLists.vue'
import AdminOthers from '../components/admin/files/Others.vue'
import AdminCategory from '../components/admin/Category.vue'
import AdminCompany from '../components/admin/Company.vue'
import AdminProduct from '../components/admin/AdminProduct.vue'
import AdminIndex from '../components/admin/Index.vue'
import AdminLinks from '../components/admin/Links.vue'
import AdminRouterView from '../components/admin/AdminRouterView.vue'
import ColorProfiles from '../components/ColorProfiles.vue'
import Company from '../components/Company.vue'
import Imprint from '../components/Imprint.vue'
import Companies from '../components/Companies.vue'
import Index from '../components/Index.vue'
import Links from '../components/Links.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/admin',
      component: AdminIndex,
      props: true,
      redirect: 'admin/companies',
      name: 'AdminIndex',
      meta: {
        breadcrumb: () => 'Startseite'
      },
      beforeEnter: async (to, from, next) => {
        let authorization = store.defaultHeaders['authorization']

        if (!authorization) {
          let cookies = cookie.parse(document.cookie)
          authorization = cookies['Authorization']
        }

        if (!authorization) {
          next('/')
        } else {
          try {
            await fetch('/api/users/me', {
              headers: new Headers({
                'Accept': 'application/json',
                'Authorization': authorization
              }),
              credentials: 'omit'
            })
            store.defaultHeaders['authorization'] = authorization
            next()
          } catch (e) {
            next('/')
          }
        }
      },
      children: [
        {
          path: 'companies',
          name: 'AdminCompanies',
          component: Companies,
          props: true,
          meta: {
            breadcrumb: () => 'Startseite'
          }
        },
        {
          path: 'links',
          name: 'AdminLinks',
          component: AdminLinks,
          props: true,
          meta: {
            breadcrumb: () => 'Links'
          }
        },
        {
          path: 'price-list',
          name: 'AdminPriceLists',
          component: AdminPriceLists,
          props: true,
          meta: {
            breadcrumb: () => 'Preislisten'
          }
        },
        {
          path: 'data-sheet',
          name: 'AdminDataSheets',
          component: AdminDataSheets,
          props: true,
          meta: {
            breadcrumb: () => 'Datenblätter'
          }
        },
        {
          path: 'color-profile',
          name: 'AdminColorProfiles',
          component: AdminColorProfiles,
          props: true,
          meta: {
            breadcrumb: () => 'Farbprofile'
          }
        },
        {
          path: 'other',
          name: 'AdminOthers',
          component: AdminOthers,
          props: true,
          meta: {
            breadcrumb: () => 'Sonstige'
          }
        },
        {
          path: ':companyId',
          name: 'AdminCompany',
          component: AdminRouterView,
          props: true,
          redirect: '/admin/:companyId/',
          meta: {
            bcDynamic: true,
            breadcrumb: (route: Route) => {
              return store.get('companies', route.params.companyId)
            }
          },
          children: [
            {
              path: '',
              name: 'AdminCompanyHome',
              component: AdminCompany,
              props: true
            },
            {
              path: ':categoryId',
              component: AdminRouterView,
              name: 'AdminCategory',
              redirect: '/admin/:companyId/:categoryId/',
              props: true,
              meta: {
                bcDynamic: true,
                breadcrumb: (route: Route) => {
                  return store.get('companies/categories', route.params.categoryId, {
                    companyId: route.params.companyId,
                    categoryId: route.params.categoryId
                  })
                }
              },
              children: [
                {
                  path: '',
                  component: AdminCategory,
                  props: true,
                  name: 'AdminCategoryHome'
                },
                {
                  path: ':productId',
                  name: 'AdminProduct',
                  component: AdminProduct,
                  props: true,
                  meta: {
                    bcDynamic: true,
                    breadcrumb: (route: Route) => {
                      return store.get('companies/categories/products', route.params.productId, {
                        companyId: route.params.companyId,
                        categoryId: route.params.categoryId,
                        productId: route.params.productId
                      })
                    }
                  }
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: '/',
      name: 'Home',
      redirect: '/',
      component: Index,
      meta: {
        breadcrumb: () => 'Startseite'
      },
      children: [
        {
          path: '',
          name: 'Index',
          component: Companies,
          props: true,
          meta: {
            breadcrumb: () => 'Unternehmen'
          }
        },
        {
          path: 'color-profile',
          name: 'ColorProfile',
          component: ColorProfiles,
          props: true,
          meta: {
            breadcrumb: () => 'Farbprofile'
          }
        },
        {
          path: 'imprint',
          name: 'Imprint',
          component: Imprint,
          props: true,
          meta: {
            breadcrumb: () => 'Impressum'
          }
        },
        {
          path: 'links',
          name: 'Links',
          component: Links,
          props: true,
          meta: {
            breadcrumb: () => 'Links'
          }
        },
        {
          path: ':companyId',
          name: 'Company',
          component: Company,
          props: true,
          meta: {
            bcDynamic: true,
            breadcrumb: (route: Route) => {
              let hash = route.hash.substring(1).split('.')
              const params = {
                companyId: route.params.companyId,
                categoryId: hash[0],
                productId: hash[1]
              }
              return store.get('companies/categories/products', params.productId, params)
            }
          }
        }
      ]
    }
  ]
})
