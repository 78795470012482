import { Model } from 'vue-powernap'

export default class FixedFormat extends Model {
  get name () {
    return 'companies/categories/products/fixed-formats'
  }

  url (params = {}) {
    return `/api/companies/${params.companyId}/product-categories/${params.categoryId}/products/${params.productId}/fixed-formats`
  }

  get fields () {
    return {
      id: this.integer(),
      page: this.string(),
      printSpace: this.string(''),
      bleedFormat: this.string(''),
      productId: this.string()
    }
  }

  get relationships () {
    return {
      product: this.hasOne('companies/categories/products', 'productId')
    }
  }
}
