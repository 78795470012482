
  import Vue from 'vue'

  export default Vue.extend({
    name: 'FileInput',
    props: {
      value: {
        type: [String, Array, Object, Number, FileList],
        default: null
      },
      accept: {
        type: String,
        default: '*'
      },
      disabled: {
        type: Boolean,
        default: false
      },
      errorMessages: {
        type: Array,
        default: (): Array<any> => []
      }
    },
    data() {
      return {
        filename: '' as string | undefined,
        fileValidation: [
          (v: any) => {
            if (!v) return 'Bitte eine Datei auswählen'
            const ending = v.split('.').reverse()[0]
            if (!(
              ending === 'pdf' ||
              ending === 'doc' ||
              ending === 'docx' ||
              ending.toLowerCase() === 'jpg' ||
              ending.toLowerCase() === 'jpeg')
            ) {
              return 'Ungültiger Dateityp. Erlaubt: .pdf, .doc, .docx, .jpg, .jpeg'
            }
            return true // file is valid
          }
        ]
      }
    },

    mounted(): void {
      this.setFileName(this.value)
    },
    methods: {
      onFocus(): void {
        if (!this.disabled) {
          (this.$refs.fileInput as HTMLElement).click()
        }
      },
      onFileChange($event: DragEvent): void {
        const files = ($event.target as HTMLInputElement).files || ($event.dataTransfer as DataTransfer).files
        if (files) {
          this.setFileName(files)
        } else {
          this.filename = ($event.target as HTMLInputElement).value.split('\\').pop()
        }
        this.$emit('input', files)
      },
      setFileName(files: FileList): void {
        if (files.length > 0) {
          this.filename = [...files].map(file => file.name).join(', ')
        }
      }
    }
  })
