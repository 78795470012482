
import Vue from 'vue'

export default Vue.extend({
  name: 'Download',
  props: {
    product: {
      type: Object,
      required: true
    }
  },

  computed: {
    showDataSheets(): boolean {
      return this.product.files.filter((file: Model<any>) => file.productFile.fileType === 'data-sheet').length > 0
    },
    showPriceLists(): boolean {
      return this.product.files.filter((file: Model<any>) => file.productFile.fileType === 'price-list').length > 0
    },
    showColorProfiles(): boolean {
      return this.product.files.filter((file: Model<any>) => file.productFile.fileType === 'color-profile').length > 0
    },
    showOthers(): boolean {
      return this.product.files.filter((file: Model<any>) => file.productFile.fileType === 'other').length > 0
    }
  }
})
