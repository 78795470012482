
  import Vue from 'vue'

  export default Vue.extend({
    name: 'TextAdTable',

    props: {
      product: {
        type: Object,
        default(): Model<any> | null {
          return null
        }
      }
    },

    data() {
      return {
        headers: [
          {
            text: 'Anzeigenteil',
            value: 'ad',
            sortable: false
          },
          {
            text: 'Textteil',
            value: 'text',
            sortable: false,
            width: '60%'
          }
        ]
      }
    },

    computed: {
      items(): Array<object> {
        let val = []
        for (let i = 0; i <= Math.max(this.product.adColumnNumber, this.product.textColumnNumber) - 1; i++) {
          val.push({
            ad: (this.product.adColumnNumber - 1) >= i ? (
              this.product.adColumnWidth + (i * this.product.adColumnWidth) + (i * this.product.adColumnSpace)
            ).toFixed(1).replace('.', ',') : '',
            text: (this.product.textColumnNumber - 1) >= i ? (
              this.product.textColumnWidth + (i * this.product.textColumnWidth) + (i * this.product.textColumnSpace)
            ).toFixed(1).replace('.', ',') : ''
          })
        }
        return val
      },
      factor(): string {
        return ((Math.round((this.product.textColumnWidth / this.product.adColumnWidth) * 10) / 10) || 0).toFixed(1).replace('.', ',')
      }
    }
  })
