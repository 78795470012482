
  import Vue from 'vue'

  import ProductFileDialog from './ProductFileDialog.vue'

  export default Vue.extend({
    name: 'ProductItemFiles',
    components: {ProductFileDialog},
    props: {
      product: {
        type: Object,
        required: true
      },
      newFiles: {
        type: Array,
        required: true
      },
      label: {
        type: String,
        required: true
      },
      fileType: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        headers: [
          {text: 'Farbprofilname', value: 'name'},
          {text: 'Bezeichnung', value: 'productFileRefDescription'},
          {text: 'Actions', value: 'action', sortable: false}
        ],
        selectedFile: null as Model<any> | null,
        selectedDataFiles: {},
        openProductFileDialog: false,
        newProductFiles: [] as Array<any>,
        productFileRefDescription: null,
        dataFilesRefDescription: null
      }
    },
    computed: {
      productFiles(): Array<Model<any>> {
        return this.$store.get('product-files').filter((item: Model<any>) => item.fileType === this.fileType)
      }
    },

    methods: {
      async addProductFileRef(): Promise<void> {
        const productFileRef = this.$store.new('companies/categories/products/files')
        productFileRef.description = this.productFileRefDescription
        productFileRef.productId = this.product.id ? this.product.id : null
        productFileRef.productFile = this.selectedFile
        this.productFileRefDescription = null
        this.selectedFile = null
        this.newFiles.push(productFileRef)
      },

      deleteRef(item: Model<any>): void {
        this.$eventBus.openInfoDialog({
          error: true,
          whiteColor: true,
          action: async () => {
            try {
              await this.$store.delete('companies/categories/products/files', item.id, {
                companyId: this.$route.params.companyId,
                productId: item.productId,
                categoryId: this.$route.params.categoryId
              })
              this.$eventBus.closeInfoDialog()
              this.$eventBus.setSuccess('Die Zuweisung wurde erfolgreich gelöscht.')
            } catch (e) {
              this.$eventBus.setError('Beim Löschen ist ein Fehler aufgetreten, bitte informieren Sie einen Administrator.')
            }
          },
          text: 'Wollen Sie wirklich diese bestehende Zuweisung löschen? Bei Bestätigung wird diese direkt gelöscht!'
        })
      },

      deleteNewRef(item: Model<any>): void {
        const idx = this.newFiles.map((p: any) => p.productFile.id + p.description).indexOf(
          item.productFile.id + item.description
        )
        if (idx !== -1) {
          this.$eventBus.openInfoDialog({
            error: true,
            whiteColor: true,
            action: async () => {
              this.$eventBus.closeInfoDialog()
              this.newFiles.splice(idx, 1)
            },
            text: 'Wollen Sie wirklich diese Zuweisung löschen?'
          })
        }
      },

      addProductFile(item: Model<any>): void {
        item.fileType = this.fileType
        this.selectedFile = item
        this.newProductFiles.push(item)
        this.openProductFileDialog = false
      }
    }
  })
