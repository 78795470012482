
  import Vue from 'vue'

  export default Vue.extend({
    name: 'CompanyCard',
    props: {
      company: {
        required: true,
        type: Object
      },
      drag: {
        required: false,
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        over: false
      }
    }
  })
