
  import Vue from 'vue'

  import ProductFileInput from './ProductFileInput.vue'

  export default Vue.extend({
    name: 'ProductFileDialog',
    components: {
      ProductFileInput
    },
    props: {
      value: {
        type: Boolean
      },
      editItem: {
        type: Object,
        required: false,
        default(): object {
          return {}
        }
      }
    },
    data() {
      return {
        dialog: false,
        item: {}
      }
    },

    watch: {
      value(val: boolean): void {
        this.dialog = val
      },
      editItem(val: object): void {
        this.item = val
      },
      dialog(val: boolean): void {
        this.$emit('input', val)
      }
    },

    mounted(): void {
      this.dialog = this.value
      this.item = this.editItem
    },

    methods: {
      async save(): Promise<void> {
        const valid = await (this.$refs.form as HTMLFormElement).validate()

        if (valid) {
          this.$emit('save', this.item)
        }
      }
    }
  })
