
  import Vue from 'vue'

  export default Vue.extend({
    name: 'AdminRouterView',
    data() {
      return {
        rerender: false
      }
    }
  })
