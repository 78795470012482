
  import Vue from 'vue'

  import Loading from '../../Loading.vue'
  import ProductFileDialog from '../../ProductFileDialog.vue'

  export default Vue.extend({
    name: 'DataFiles',
    components: {
      ProductFileDialog,
      Loading
    },
    props: {
      fileType: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        headers: [
          {text: 'Produktname', value: 'name', width: '55%'},
          {text: 'Bezeichnung', value: 'description'},
          {text: 'Actions', value: 'actions', sortable: false, align: 'end'}
        ],
        expanded: [],
        stepper: 1,
        dialog: false,
        isSomethingNew: false,
        dialogWarning: false,
        editedItem: {} as Model<any>,
        loading: true,
        selectedFile: {} as Model<any>,
        company: {} as Model<any>,
        category: {} as Model<any>,
        product: {},
        productFileRef: {} as Model<any>,
        productFileRefs: [] as Array<Model<any>>
      }
    },
    computed: {
      companies(): Array<Model<any>> {
        return this.$store.get('companies')
      },
      categories(): Array<Model<any>> {
        if (this.company.id) {
          return this.$store.get('companies/categories', null, {companyId: this.company.id})
        } else return []
      },
      products(): Array<Model<any>> {
        if (this.company.id && this.category.id) {
          return this.$store.get('companies/categories/products', null, {
            companyId: this.company.id,
            categoryId: this.category.id
          })
        } else return []
      },
      productFiles(): Array<Model<any>> {
        return this.$store.get('product-files')
      }
    },
    async mounted(): Promise<void> {
      await this.$store.findAll('product-files', {}, {
        params: {
          'type': this.fileType
        }
      })
      this.loading = false
    },
    methods: {
      resetAll(final?: boolean): void {
        this.resetToCompany()
        this.stepper = 1
        this.productFileRefs = []
        this.productFileRef = {}
        this.selectedFile = {}
        if (final && this.isSomethingNew) {
          this.$eventBus.setSuccess('Die Zuweisungen wurden erfolgreich angelegt.')
          this.isSomethingNew = false
        }
      },
      openWarningDialog(): void {
        if (!this.isSomethingNew) {
          this.stepper = 3
        } else {
          this.$eventBus.openInfoDialog({
            error: true,
            whiteColor: true,
            action: this.resetToCategory,
            actionButton: 'Zustimmen',
            text: 'Möchten Sie wirklich zurück? Dabei werden alle Zuweisungen die Sie gerade angelegt haben gelöscht!'
          })
        }
      },
      resetToCompany(): void {
        this.stepper = 2
        this.company = {}
        this.category = {}
        this.product = {}
      },
      async resetToCategory(): Promise<void> {
        for (let product of this.products) {
          const files = product.files.slice(0)
          for (let ref of files) {
            if (ref.isNew) {
              await this.$store.delete('companies/categories/products/files', ref.id, {
                companyId: this.company.id,
                productId: product.id,
                categoryId: this.category.id
              })
            }
          }
        }
        this.product = {}
        this.category = {}
        this.dialogWarning = false
        this.isSomethingNew = false
        this.$eventBus.closeInfoDialog()
        this.stepper = 3
      },
      setFile(file: any): void {
        this.selectedFile = file
        this.stepper = 2
      },
      async loadCategories(model: Model<any>): Promise<void> {
        this.company = model
        await this.$store.findAll('companies/categories', {
          companyId: this.company.id
        })
        this.stepper = 3
      },
      async loadProducts(model: Model<any>): Promise<void> {
        this.category = model
        await this.$store.findAll('companies/categories/products', {
          companyId: this.company.id,
          categoryId: this.category.id
        })
        for (let product of this.products) {
          await this.$store.findAll('companies/categories/products/files', {
            companyId: this.company.id,
            productId: product.id,
            categoryId: this.category.id
          })
        }
        this.stepper = 4
      },
      editItem(item: Model<any>): void {
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem(item: Model<any>): void {
        this.$eventBus.openInfoDialog({
          error: true,
          whiteColor: true,
          action: async () => {
            try {
              await this.$store.delete('product-files', item.id)
              this.$eventBus.closeInfoDialog()
              this.$eventBus.setSuccess('Der Eintrag wurde erfolgreich gelöscht.')
            } catch (e) {
              this.$eventBus.setError('Beim Löschen ist ein Fehler aufgetreten, bitte informieren Sie einen Administrator.')
            }
          },
          text: 'Wollen Sie wirklich diesen Eintrag löschen?'
        })
      },

      deleteRef(item: Model<any>): void {
        this.$eventBus.openInfoDialog({
          error: true,
          whiteColor: true,
          action: async () => {
            try {
              await this.$store.delete('companies/categories/products/files', item.id, {
                companyId: this.company.id,
                productId: item.productId,
                categoryId: this.category.id
              })
              this.$eventBus.closeInfoDialog()
              this.$eventBus.setSuccess('Die Zuweisung wurde erfolgreich gelöscht.')
            } catch (e) {
              this.$eventBus.setError('Beim Löschen ist ein Fehler aufgetreten, bitte informieren Sie einen Administrator.')
            }
          },
          text: 'Wollen Sie wirklich dieses Zuweisung löschen?'
        })
      },

      close(): void {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({})
        }, 300)
      },

      async save(item: Model<any>): Promise<void> {
        let formData = new FormData()
        formData.append('file', item.file)

        if (typeof item.file.name === 'string') {
          try {
            let picture = null
            if (item.fileId) {
              picture = await this.$store.call('update-file', {
                id: item.fileId,
                data: formData
              })
            } else {
              picture = await this.$store.call('upload-file', {
                data: formData
              })
            }

            item.fileId = picture.id
            item.fileType = this.fileType
          } catch (e) {
            this.$eventBus.setError('Das Bild konnte nicht hochgeladen werden. Bitte überprüfen Sie ihr Bild!')
          }

          if (item.id) {
            try {
              await this.$store.update('product-files', item)
              this.$eventBus.setSuccess('Der Eintrag wurde erfolgreich aktualisiert')
            } catch (e) {
              this.$eventBus.setError('Der Eintrag konnte nicht aktualisiert werden. Bitte überprüfen Sie ihre Eingabe!')
            }
          } else {
            try {
              await this.$store.create('product-files', item)
              this.$eventBus.setSuccess('Der Eintrag wurde erfolgreich angelegt')
            } catch (e) {
              this.$eventBus.setError('Der Eintrag konnte nicht angelegt werden. Bitte überprüfen Sie ihre Eingabe!')
            }
          }
        }

        this.close()
      },

      async saveProductFileRef(props: Model<any>): Promise<void> {
        const productFileRef = this.$store.new('companies/categories/products/files')
        const item = props.item
        productFileRef.description = item.fileRefDescription
        productFileRef.productFileId = this.selectedFile.id
        productFileRef.productId = item.id
        this.$store.create('companies/categories/products/files', productFileRef, {
          companyId: this.company.id,
          productId: item.id,
          categoryId: this.category.id
        }).then(() => {
          props.expanded = true
          this.isSomethingNew = true
          this.$eventBus.setSuccess('Die Zuweisung wurde erfolgreich hinzugefügt.')
          item['fileRefDescription'] = ''
        }).catch((e: Error) => {
          this.$eventBus.setError('Bei Ihrer Zuweisung sind Fehler aufgetreten. Eventuell existiert bereits eine gleiche Zuweisung!')
        })
      }
    }
  })
