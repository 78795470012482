
  import Vue from 'vue'

  export default Vue.extend({
    name: 'CategoryDialog',
    model: {
      prop: 'category', // set v-model to category instead of value
      event: 'change'
    },
    props: {
      category: {
        type: Object,
        default(): object {
          return this.$store.new('companies/categories')
        }
      },
      noDelete: {
        type: Boolean,
        default(): boolean {
          return false
        }
      }
    },
    data() {
      return {
        height: null,
        width: null,
        dialog: false,
        categoryCopy: {}
      }
    },
    mounted(): void {
      this.categoryCopy = Object.assign({}, this.category)
    },
    methods: {
      async save(): Promise<void> {
        const valid = await this.$validator.validateAll()
        if (valid) {
          this.$emit('save', this.categoryCopy)
          this.close()
        }
      },
      close(): void {
        this.dialog = false
        this.$validator.reset()
        if (this.category.companyId) {
          this.categoryCopy = Object.assign({}, this.$store.get(
            'companies/categories',
            this.category.id,
            {
              companyId: this.category.companyId
            })
          )
        } else {
          this.categoryCopy = {}
        }
      },
      openWarningDialog(): void {
        this.dialog = false
        this.$eventBus.openInfoDialog({
          error: true,
          whiteColor: true,
          action: this.deleteCategory,
          text: 'Möchten Sie wirklich die Kategorie löschen? Alle zugehörigen Produkte werden ebenfalls gelöscht!'
        })
      },
      deleteCategory(): void {
        this.$eventBus.closeInfoDialog()
        this.$emit('delete', this.categoryCopy)
        this.close()
      }
    }
  })
