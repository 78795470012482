
  import Vue from 'vue'

  import Download from './Download.vue'
  import Format from './Format.vue'
  import Upload from './Upload.vue'

  export default Vue.extend({
    name: 'Product',
    components: {
      Download,
      Format,
      Upload
    },
    props: {
      product: {
        type: Object,
        required: true
      }
    },

    async mounted(): Promise<void> {
      await this.$store.findAll('companies/categories/products/files', {
        companyId: this.$route.params.companyId,
        categoryId: this.product.categoryId,
        productId: this.product.id
      })

      await this.$store.findAll('companies/categories/products/fixed-formats', {
        companyId: this.$route.params.companyId,
        categoryId: this.product.categoryId,
        productId: this.product.id
      })
    }
  })
