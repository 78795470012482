
  import Vue from 'vue'

  import Loading from './Loading.vue'

  export default Vue.extend({
    name: 'Links',
    components: {
      Loading
    },

    data() {
      return {
        loading: true
      }
    },

    computed: {
      links(): Array<any> {
        return this.$store.get('links')
      }
    },

    async mounted(): Promise<void> {
      this.loading = true
      await this.$store.findAll('links')
      this.loading = false
    }
  })
