import '@mdi/font/css/materialdesignicons.css'
import 'typeface-roboto/index.css'
import 'typeface-merriweather/index.css'

import Raven from 'raven-js'
// @ts-ignore
import RavenVue from 'raven-js/plugins/vue'
import VeeValidate, {Validator} from 'vee-validate'
// @ts-ignore
import de from 'vee-validate/dist/locale/de'
import Vue from 'vue'
import Breadcrumbs from './components/Breadcrumbs.vue'

import App from './App.vue'
import router from './router'
import eventBus from './plugins/event-bus'
import vuetify from './plugins/vuetify'
import store from './models/index'

Vue.config.productionTip = false

if (process.env.VUE_APP_SENTRY_DSN) {
  Raven
    .config(process.env.VUE_APP_SENTRY_DSN)
    .addPlugin(RavenVue, Raven)
    .install()
}

Vue.use(eventBus)
Vue.use(store)
Vue.use(VeeValidate)

de.attributes = {
  publicationDateFormatted: 'Erscheinungstermin',
  phone: 'Telefon',
  mail: 'E-Mail Adresse',
  name: 'Name',
  height: 'Höhe',
  advertiser: 'Inserent',
  file: 'Datei',
  description: 'Beschreibung',
  fixedFormat: 'Festformate',
  counterpartPhone: 'Telefon',
  counterpartMail: 'E-Mail',
  counterpartFax: 'Fax',
  counterpartNotes: 'Erreichbarkeit / Anmerkung',
  printMethod: 'Druckverfahren',
  printSpace: 'Satzspiegel',
  adColumnWidth: 'Anzeigenteil (Spaltenbreite)',
  adColumnSpace: 'Anzeigenteil (Spaltenabstand)',
  adColumnNumber: 'Anzeigenteil (Zeilenanzahl)',
  textColumnWidth: 'Textteil (Spaltenbreite)',
  textColumnSpace: 'Textteil (Spaltenabstand)',
  textColumnNumber: 'Textteil (Zeilenanzahl)',
  deliveryTime: 'Datenlieferungszeiten',
  counterpartBookingName: 'Name',
  counterpartBookingPhone: 'Telefon',
  counterpartBookingMail: 'E-Mail'

}

de.dateFormat = 'DD.MM.YYYY'
Validator.localize('de', de)
Validator.extend('fileSize', {
  getMessage: () => 'Die maximale Dateigröße von 100 MB ist überschritten!',
  validate: (value) => {
    return value.length > 0 ? value[0].size <= 100000000 : true
  }
})
Validator.extend('includesInvalidChars', {
  getMessage: () => 'Bitte verwenden Sie kein \\/:*?“<>| im Namen',
  validate: (value, args: any) => {
    for (let char of args) {
      if (value.includes(char) || value.includes('|')) {
        return false
      }
    }
    return true
  }
})
Vue.component('Breadcrumbs', Breadcrumbs)

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
