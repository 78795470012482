
  import Vue from 'vue'

  export default Vue.extend({
    name: 'ProductFixedFormat',
    props: {
      value: {
        type: [Array],
        default(): Array<any> {
          return []
        }
      },
      printSpace: {
        type: [String, Boolean],
        required: false,
        default: false
      },
      bleedFormat: {
        type: [String, Boolean],
        required: false,
        default: false
      },
      editable: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data() {
      return {
        headers: [
          {
            text: 'Seite',
            value: 'page',
            sortable: false
          },
          {text: 'Satzspiegel', value: 'printSpace', sortable: false},
          {text: 'Anschnitt', value: 'bleedFormat', sortable: false},
          {text: 'Actions', value: 'action', sortable: false}
        ],
        fixedFormats: [] as Array<any>,
        changedEntries: {} as any,
        lastEntry: {}
      }
    },
    watch: {
      value(val: Array<any>): void {
        this.fixedFormats = val.slice()
        if (this.editable) {
          this.lastEntry = this.$store.new('companies/categories/products/fixed-formats')
          this.fixedFormats.push(this.lastEntry)
        }
      },
      lastEntry: {
        handler: function (val: any): void {
          if (val.page) {
            this.lastEntry = this.$store.new('companies/categories/products/fixed-formats')
            this.fixedFormats.push(this.lastEntry)
          }
        },
        deep: true
      }
    },
    mounted(): void {
      this.fixedFormats = this.value.slice()

      if (this.editable) {
        this.lastEntry = this.$store.new('companies/categories/products/fixed-formats')
        this.fixedFormats.push(this.lastEntry)
      }
    },
    methods: {
      deleteItem(index: number, item: Model<any>) {
        if (item.id) {
          this.$eventBus.openInfoDialog({
            error: true,
            whiteColor: true,
            action: async () => {
              try {
                await this.$store.delete('companies/categories/products/fixed-formats', item.id, {
                  companyId: this.$route.params.companyId,
                  productId: item.productId,
                  categoryId: this.$route.params.categoryId
                })
                this.$eventBus.closeInfoDialog()
                this.$eventBus.setSuccess('Die Zeile wurde erfolgreich gelöscht.')
              } catch (e) {
                this.$eventBus.setError('Beim Löschen ist ein Fehler aufgetreten, bitte informieren Sie einen Administrator.')
              }
            },
            text: 'Wollen Sie wirklich die bereits gespeicherte Zeile löschen? Bei Bestätigung wird diese direkt gelöscht!'
          })
        } else {
          if (item !== this.lastEntry) {
            this.fixedFormats.splice(index, 1)
          }
        }
      },
      changeItem(index: number, item: Model<any>) {
        this.changedEntries[index] = item
        this.$emit('input', this.changedEntries)
      }
    }
  })
