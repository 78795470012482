import Vue from 'vue'
import {DialogOptions} from '@/types'

export default new Vue({
  data() {
    return {
      state: '',
      message: '',
      infoDialogOptions: {} as DialogOptions
    }
  },

  computed: {
    snackbar: {
      get(): boolean {
        return !!this.state
      },
      set(value: boolean): void {
        if (!value) {
          this.state = ''
          this.message = ''
        }
      }
    },
  },

  methods: {
    install(Vue: any) {
      Vue.prototype.$eventBus = this
    },

    setError(message: string): void {
      this.state = 'error'
      this.message = message
    },
    setSuccess(message: string): void {
      this.state = 'success'
      this.message = message
    },
    openInfoDialog(options: DialogOptions): void {
      this.infoDialogOptions = Object.assign({
        dialog: true,
        maxWidth: 350,
        whiteColor: false,
        title: 'Achtung!',
        closeButton: 'Abbrechen',
        actionButton: 'Löschen',
        actionButtonColor: 'red darken-1'
      }, options)
    },
    closeInfoDialog(): void {
      this.infoDialogOptions.dialog = false
    }
  }
})
