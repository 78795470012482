
  import Vue from 'vue'
  import Product from './Product.vue'

  export default Vue.extend({
    name: 'CompanyTabs',
    components: {Product},
    model: {
      prop: 'company', // set v-model to company instead of value
      event: 'change'
    },
    props: {
      company: {
        type: Object,
        default: () => {
        }
      }
    },
    data() {
      return {
        offset: 0,
        fixed: false,
        tab: ''
      }
    },
    watch: {
      offset() {
        this.fixed = this.offset >= (document.getElementsByClassName('v-content__wrap')[0] as HTMLElement).offsetTop
      },
      '$route'() {
        if (this.$route.hash && this.company && this.company.categories.length > 1) {
          const categorySlug = this.$route.hash.split('.')[0].substring(1)
          const activeTab = this.$el.querySelector('.v-tabs-bar__content a.v-tab--active')
          if (activeTab) {
            activeTab.classList.remove('v-tab--active')
          }
          const category = this.company.categories.find((item: Model<any>) => item.id === categorySlug)
          if (category && category.products.length > 1) {
            (
              this.$el.querySelector(
                `a.v-tab#${categorySlug}`
              ) as HTMLElement
            ).classList.add('v-tab--active')
          }
        }
      }
    },
    mounted(): void {
      if (this.$route.hash && this.company.categories.length > 1) {
        const categorySlug = this.$route.hash.split('.')[0].substring(1)
        if (this.company.categories.find((item: Model<any>) => item.id === categorySlug).products.length > 1) {
          const activeTab = this.$el.querySelector('.v-tabs-bar__content a.v-tab--active')
          if (activeTab) {
            activeTab.classList.remove('v-tab--active')
          }
          (
            this.$el.querySelector(
              `a.v-tab#${categorySlug}`
            ) as HTMLElement
          ).classList.add('v-tab--active')
        }
      } else {
        this.$router.replace(`${this.company.id}#${this.company.categories[0].id}.${this.company.categories[0].products[0].id}`)
      }
    },
    methods: {
      onScroll() {
        this.offset = window.pageYOffset || document.documentElement.scrollTop
      }
    }
  })
