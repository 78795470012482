
  import Vue from 'vue'
  import FileStepper from './FileStepper.vue'

  export default Vue.extend({
    name: 'AdminPriceLists',
    components: {
      FileStepper
    }
  })
