
  import Vue from 'vue'

  export default Vue.extend({
    inject: ['$validator'],
    name: 'NumberInput',
    props: {
      value: {
        type: [String, Number],
        required: false,
        default(): number | string {
          return 0.0
        }
      },
      scale: {
        type: Number,
        required: false,
        default(): number {
          return 2
        }
      },
      label: {
        type: String,
        required: false,
        default(): string {
          return 'Label'
        }
      },
      errorMessages: {
        type: [Array, String, Object],
        required: false,
        default(): any {
          return null
        }
      },
      validate: {
        type: String,
        required: false,
        default: null
      },
      dataVvName: {
        type: String,
        required: false,
        default: null
      },
      required: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data: () => ({
      formattedValue: ''
    }),
    watch: {
      value(value) {
        this.formattedValue = Number(value).toFixed(this.scale).replace('.', ',')
      }
    },
    mounted() {
      this.formattedValue = Number(this.value).toFixed(this.scale).replace('.', ',')
    },
    methods: {
      updateValue(value: string) {
        const formattedValue = Number(value.replace(',', '.')).toFixed(this.scale).replace('.', ',')
        if (formattedValue !== value) {
          this.formattedValue = formattedValue
        }

        this.$emit('input', Number(formattedValue.replace(',', '.')))
      }
    }
  })
