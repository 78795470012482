
  import Vue from 'vue'

  import Loading from '../Loading.vue'

  export default Vue.extend({
    name: 'AdminLinks',
    components: {
      Loading
    },

    data() {
      return {
        loading: true,
        newLinks: [] as Array<Model<any>>,
        currentLink: {} as Model<any>,
        headers: [
          {
            text: 'Name',
            align: 'left',
            sortable: false,
            value: 'name',
            width: '45%'
          },
          {
            text: 'Link',
            align: 'left',
            sortable: false,
            value: 'link',
            width: '45%'
          },
          {
            sortable: false,
            width: '10%'
          }
        ]
      }
    },

    computed: {
      links(): Array<Model<any>> {
        return this.$store.get('links')
      }
    },

    async mounted(): Promise<void> {
      this.loading = true
      await this.$store.findAll('links')
      this.loading = false
    },

    methods: {
      openDialogWarning(link: Model<any>): void {
        this.currentLink = link
        this.$eventBus.openInfoDialog({
          error: true,
          whiteColor: true,
          action: this.deleteLink,
          text: 'Möchten Sie wirklich die Kategorie löschen? Alle zugehörigen Produkte werden ebenfalls gelöscht!'
        })
      },
      addLink(): void {
        this.newLinks.push(Object.assign(this.$store.new('links'), {
          isNew: true
        }))
      },
      async updateLink(link: Model<any>): Promise<void>{
        if (link.id === null) {
          await this.$store.create('links', link)
          this.newLinks.splice(this.newLinks.indexOf(link), 1)
          this.$eventBus.setSuccess('Der Link wurde erfolgreich hinzugefügt.')
        } else {
          await this.$store.update('links', link)
          this.$eventBus.setSuccess('Der Link wurde erfolgreich geändert.')
        }
      },
      async deleteLink(): Promise<void> {
        await this.$store.delete('links', this.currentLink.id)
        this.$eventBus.closeInfoDialog()
        this.currentLink = {}
        this.$eventBus.setSuccess('Der Link wurde erfolgreich gelöscht.')
      }
    }
  })
